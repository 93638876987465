<template>
  <div>
    <ul>
      <li
        v-for="folder in folders.list"
        :key="folder.index"
        :title="folder.name"
      >
        <b-row>
          <b-col cols="11">
            <span class="select" v-on:click="select(folder)">{{ folder.name }}</span>
          </b-col>
          <b-col cols="1">
            <b-dropdown
              id="dropdown-grouped"
              variant="flat-primary"
              right
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="edit_folder(folder)"
                >Editer</b-dropdown-item
              >
              <b-dropdown-item v-on:click="delete_folder(folder.id_library)">
                Supprimer
              </b-dropdown-item>
              <b-dropdown-item
                v-on:click="add_folder(folder.id_library, folder.name)"
              >
                Ajouter un sous-dossier
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>

        <ul>
          <li
            v-for="_folder in folder.folders"
            :key="_folder.index"
            :title="_folder.name"
          >
            <b-row>
              <b-col cols="11">
                <span class="select" v-on:click="select(_folder)">{{ _folder.name }}</span>
              </b-col>
              <b-col cols="1">
                <b-dropdown
                  id="dropdown-grouped"
                  variant="flat-primary"
                  right
                  size="sm"
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <b-dropdown-item @click="edit_folder(_folder, folder.name)"
                    >Editer</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-on:click="delete_folder(_folder.id_library)"
                  >
                    Supprimer
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-on:click="add_folder(_folder.id_library, _folder.name)"
                  >
                    Ajouter un sous-dossier
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <ul>
              <li
                v-for="__folder in _folder.folders"
                :key="__folder.index"
                :title="__folder.name"
              >
                <b-row>
                  <b-col cols="11">
                    <span class="select" v-on:click="select(__folder)">{{ __folder.name }}</span>
                  </b-col>
                  <b-col cols="1">
                    <b-dropdown
                      id="dropdown-grouped"
                      variant="flat-primary"
                      right
                      size="sm"
                      class="dropdown-icon-wrapper"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <b-dropdown-item @click="edit_folder(__folder, _folder.name)"
                        >Editer</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-on:click="delete_folder(__folder.id_library)"
                      >
                        Supprimer
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <b-modal
      ref="modal-add-folder"
      id="modal-add-folder"
      cancel-variant="outline-secondary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Ajouter un dossier"
      :hide-footer="true"
    >
      <b-form>
        <b-form-group>
          <label>Créer un dossier</label>
          <b-form-input v-model="folder.name"></b-form-input>
          <br />
          <p v-if="folder.parent > 0">Parent : {{ folder.parent_name }}</p>
          <br />
          <b-button block v-on:click="create_folder" variant="primary">
            <span class="align-middle">Créer</span>
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      ref="modal-upd-folder"
      id="modal-upd-folder"
      cancel-variant="outline-secondary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Modifier un dossier"
      :hide-footer="true"
    >
      <b-form>
        <b-form-group>
          <label>Modifier un dossier</label>
          <b-form-input v-model="folder.name"></b-form-input>
          <br />
          <p v-if="folder.parent > 0">Parent : {{ folder.parent_name }}</p>
          <br />
          <b-button block v-on:click="update_folder" variant="primary">
            <span class="align-middle">Mettre à jour</span>
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BBadge,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  props: {
    p_folders: {
      type: Array,
    },
  },
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BForm,
    BFormGroup,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardBody,
  },
  data() {
    return {
      folder: {
        name: "",
        parent: 0,
        parent_name: null,
        id_folder: null
      },
      folders: {
        list: [],
      },
    };
  },
  mounted() {
      this.$set(this.folders, "list", this.p_folders);
  },
  methods: {
    select(folder) {
      console.log(folder)
      this.$emit("chose_library", folder);
    },
    add_folder(parent, parent_name) {
      this.folder.parent = parent;
      this.folder.parent_name = parent_name;
      this.$refs["modal-add-folder"].show();
    },
    edit_folder(folder, parent_name) {
      this.folder = folder;
      this.folder.parent_name = parent_name;
      this.$refs["modal-upd-folder"].show();
    },
    create_folder() {
      this.$http
        .post($themeConfig.app.restEndPoint + "/library/create", this.folder)
        .then((response) => {
          this.$set(this.folders, "list", response.data);
          this.$refs["modal-add-folder"].hide();
          this.folder = {
            name: "",
            parent: 0,
            parent_name: null,
            id_folder: null
          }
        });
    },
    update_folder() {
      this.$http
        .post($themeConfig.app.restEndPoint + "/library/update", this.folder)
        .then((response) => {
          this.$set(this.folders, "list", response.data);
          this.$refs["modal-upd-folder"].hide();
          this.folder = {
            name: "",
            parent: 0,
            parent_name: null,
            id_folder: null
          }
        });
    },
    delete_folder(id_folder){
      this.$http
        .post($themeConfig.app.restEndPoint + "/library/delete", {"id_library":id_folder})
        .then((response) => {
          this.$set(this.folders, "list", response.data);
        });
    }
  }
};
</script>

<style >
.select {
  cursor: pointer;
}
</style>