<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-card no-body class="card-apply-job">
          <b-card-header class="pb-1">
            <b-button
              variant="relief-primary"
              class="btn-icon float-right"
              v-on:click="add_folder(0, '')"
            >
              <feather-icon icon="BookmarkIcon" /> Ajouter un dossier
            </b-button>
          </b-card-header>
          <b-card-body>
            <list-library v-if="folders.list.length>0" :p_folders="folders.list" v-on:chose_library="chose_library" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-card>  
          <p v-if="calls.list.length==0">Aucun RT dans ce dossier</p>
          <b-card v-else v-for="call in calls.list" :key="call.index">
          <b-row>
            <b-col cols="5">
              <b-card-title class="mb-1">
                <b-link :to="{ name: 'call', params: { id_call: call.id } }">{{
                  call.titre
                }}</b-link>
              </b-card-title>
              <div class="font-small-2">
                {{ call.nicedate }}
              </div>
              <h5 class="mb-1">
                {{ call.interlocuteur_rivalis }}
                <br />
                <span v-if="call.hubspot.contact.properties">
                  {{ call.hubspot.contact.properties.firstname }} {{ call.hubspot.contact.properties.lastname }}
                </span>
              </h5>
              <b-badge pill variant="light-dark">{{tagname[call.id_tag]}}</b-badge>
              <p>&nbsp;</p>
              <p><b-button size="sm" v-on:click="remove(call.id)">Retirer</b-button></p>
            </b-col>
            <b-col cols="4">
              <div v-if="call.comment!=''">
              <p><b>{{call.user.prenom}} {{call.user.nom}}</b></p>
              <p>{{call.comment}}</p>
              </div>
            </b-col>
            <b-col cols="3">
              <img
                :src="themeConfig.app.restEndPoint + '/' + call.thumbnail"
                class="float-right"
              />
            </b-col>
          </b-row>
        </b-card>
        </b-card>
      </b-col>
    </b-row>
    
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BBadge,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

import ListLibrary from "@core/components/libraries/ListLibrary.vue";


export default {
  components: {
    ListLibrary,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BForm,
    BFormGroup,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardBody,
  },
  data() {
    return {
      themeConfig: $themeConfig,
      tagname:[],
      chosen_folder: null,
      folder: {
        name: "",
        parent: 0,
      },
      folders: {
        list: [],
      },
      calls: {
        list: [],
      },
    };
  },
  methods: {
    user () {
      var user = localStorage.getItem("user")
      return user["id_user"]
    },
    remove(id_call) {
      this.$http
        .post($themeConfig.app.restEndPoint + "/call/" + id_call + "/library/disassociate", {"folder": this.chosen_folder, "id_user": this.user()})
        .then((response) => {
          this.chose_library(this.chosen_folder)
        });
    },
    
    
    chose_library(folder){
      this.chosen_folder = folder
      this.$http.post($themeConfig.app.restEndPoint + "/calls/library", {"folder": folder, "id_user": this.user()}).then((response) => {
      var tags = response.data["tags"]
      for (var i=0; i < tags.length; i++) {
        this.tagname[tags[i]["id_tag"]] = tags[i]["name"]
      }
      this.$set(this.calls, "list", response.data["calls"]);
    });
    }
  },
  mounted() {
    this.$http.post($themeConfig.app.restEndPoint + "/library/list").then((response) => {
      this.$set(this.folders, "list", response.data);
    });
  },
};
</script>